<template>
  <div>
    <div class="vx-row mt-3">
      <header class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10">
        <h4>{{ $t("apps.photos.title") }}</h4>
        <p>{{ $t("apps.photos.titledescr") }}</p>
      </header>
      <div class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-10 text-right md:space-x-4">
        <vs-button
          class="xs:w-full sm:w-auto rounded-lg mb-4 xs:mr-4 sm:mr-4 md:mr-0"
          :to="{ name: exportRouteName, params: { type: 'csv' } }" >
          {{ $t("apps.photos.exportcsv") }}
        </vs-button>
        <vs-button
          class="xs:w-full sm:w-auto rounded-lg"
          :to="{ name: exportRouteName, params: { type: 'zip' } }" >
          {{ $t("apps.photos.exportzip") }}
        </vs-button>
      </div>
    </div>
    <div>
      <Datatable
        :config="datatableConfig"
        :elements="photos"
        :stripe="true"
        :draggable="false"
        :sst="true"
        :pagination="pagination"
        @action="handleAction"
        @filter="handleFilter"
      />
    </div>
    <div class="modalPhoto">
      <vs-popup :active.sync="modalOpenPhoto" :title="''" classContent="modalPhoto">
        <div class="containerPopUp">
          <div class="imagePopUp">
            <img :src="selectedPhoto">
          </div>
          <div>
            <div class="flex justify-center">
              <div>
                <vs-pagination class="mt-2" :total="totalPhotos" v-model="currentPhotoIndex" />
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>

import { DateTime } from 'luxon';
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import loader from "@/modules/Shared/Mixins/loader.js";

export default {
  name: 'list-photos-page',
  components: {
    Datatable
  },
  props: {
    getPhotosFunction: {
      type: Function,
      required: true
    },
    datatableConfig: {
      type: Array,
      required: true
    },
    exportRouteName: {
      type: String,
      required: true
    },
  },
  mixins: [loader],
  data () {
    return {
      photos: [],
      modalOpenPhoto: false,
      pagination: {},
      currentPhotoIndex: 1
    }
  },
  computed: {
    totalPhotos() {
      return this.photos.length ? this.photos.length : 0
    },
    selectedPhoto() {
      return this.photos.length ? this.photos[this.currentPhotoIndex - 1].path : ''
    }
  },
  methods: {
    async getPhotos(parameters) {
      this.currentPhotoIndex = 1
      const photos = await this.getPhotosFunction(this.$route.params.uuid, {...parameters, })
      this.pagination = {
        per_page: photos.per_page,
        total: photos.total
      }
      this.photos = photos.data.map(
        photo => ({...photo, 'actions': [ 'view' ], 'date': DateTime.fromISO(photo.date).toFormat('dd-LL-yyyy')})
      )
    },
    handleAction(type, uuid) {
      const photoIndex = this.photos.findIndex(photo => photo.uuid === uuid)
      this.currentPhotoIndex = photoIndex + 1
      this.modalOpenPhoto = true
    },
    async handleFilter(params) {
      await this.load(async () => {
        await this.getPhotos(PaginationService.parseParameters(params))
      })
    }
  },
  async created() {
    await this.load(async () => {
      await this.getPhotos({})
    })
  }
}
</script>

<style scoped>

.modalPhoto {
  @apply block;
}

.modalPhoto .containerPopUp {
  @apply block;
}

.modalPhoto .imagePopUp {
  width: 100%;
  height: 100%;
}

.modalPhoto .imagePopUp img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

</style>
